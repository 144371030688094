import React from "react";
import { EndpointCatalogueCategoryListResponse } from "../../../datasource/catalogue/CategoryList";
import { LocalizedValue } from "../../../services/Language";

import T from "./ProductList.json";

export interface CategoryTreeProps {
    categoryList: EndpointCatalogueCategoryListResponse;
    categoryType: 1 | 2;
    selected: {
        main: number | undefined;
        secondary: number | undefined;
    };
    T: (data: LocalizedValue<string | undefined>) => string | undefined;
    openMain: (categoryId: number) => void;
    openSecondary: (categoryId: number, mainCategoryId?: number) => void;
    setRecomended: () => void;
}

const classNameBase = "_catalogue-category-tree";

export const CatalogueCategoryTree: React.FC<CategoryTreeProps> = (props) => (
    <div className={`${classNameBase}__main-list`}>
        {props.categoryType === 1 ? (
            <div className={`${classNameBase}__main`}>
                <div
                    className={`${classNameBase}__main-row ${props.selected.secondary === undefined ? "selected" : ""}`}
                    onClick={() => {
                        props.setRecomended();
                    }}
                >
                    <div className={`${classNameBase}__main-row-label`}>{props.T(T.recomended)}</div>
                    <div className={`${classNameBase}__main-row-icon`}>
                        {props.selected.secondary === undefined ? "check" : "keyboard_arrow_right"}
                    </div>
                </div>
            </div>
        ) : props.categoryType === 2 ? (
            <div className={`${classNameBase}__main`}>
                <div
                    className={`${classNameBase}__main-row ${props.selected.secondary === undefined ? "selected" : ""}`}
                    onClick={() => {
                        props.setRecomended();
                    }}
                >
                    <div className={`${classNameBase}__main-row-label`}>{props.T(T.recomended)}</div>
                    <div className={`${classNameBase}__main-row-icon`}>
                        {props.selected.secondary === undefined ? "check" : "keyboard_arrow_right"}
                    </div>
                </div>
            </div>
        ) : null}
        {props.categoryList.map((c) => {
            if (props.categoryList.length === 1) {
                return c.categories.map((cc) => (
                    <div className={`${classNameBase}__main`} key={cc.categoryId}>
                        <div
                            className={`${classNameBase}__main-row ${
                                cc.categoryId === props.selected.secondary ? "selected" : ""
                            }`}
                            onClick={() => props.openSecondary(cc.categoryId, c.categoryId)}
                        >
                            <div className={`${classNameBase}__main-row-label`}>{props.T(cc.label)}</div>
                            <div className={`${classNameBase}__main-row-icon`}>
                                {cc.categoryId === props.selected.secondary ? "check" : "keyboard_arrow_right"}
                            </div>
                        </div>
                    </div>
                ));
            }
            return (
                <div className={`${classNameBase}__main`} key={c.categoryId}>
                    <div
                        className={`${classNameBase}__main-row ${
                            c.categories.length === 1 && c.categories[0].categoryId === props.selected.secondary
                                ? "selected"
                                : ""
                        }`}
                        onClick={() => props.openMain(c.categoryId)}
                    >
                        <div className={`${classNameBase}__main-row-label`}>{props.T(c.label)}</div>
                        <div className={`${classNameBase}__main-row-icon`}>
                            {c.categories.length === 1
                                ? c.categories[0].categoryId === props.selected.secondary
                                    ? "check"
                                    : "keyboard_arrow_right"
                                : c.categoryId === props.selected.main
                                ? "keyboard_arrow_up"
                                : "keyboard_arrow_down"}
                        </div>
                    </div>
                    {c.categoryId === props.selected.main && c.categories.length !== 1 ? (
                        <div className={`${classNameBase}__secondary-list`}>
                            {c.categories.map((cc) => (
                                <div
                                    className={`${classNameBase}__secondary-row ${
                                        cc.categoryId === props.selected.secondary ? "selected" : ""
                                    }`}
                                    onClick={() => props.openSecondary(cc.categoryId)}
                                    key={cc.categoryId}
                                >
                                    <div className={`${classNameBase}__secondary-row-label`}>{props.T(cc.label)}</div>
                                    <div className={`${classNameBase}__secondary-row-icon`}>
                                        {cc.categoryId === props.selected.secondary ? "check" : "keyboard_arrow_right"}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            );
        })}
    </div>
);
